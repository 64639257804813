<template>
  <div class="iss-main theme-bg">
    <div class="valid-content">
      <tips-bar text="您可以查看或编辑有效联系人信息。">
        <template #btnList>
          <div class="btn" @click="clickHandle">返回</div>
        </template>
      </tips-bar>
      <div class="operation">
        <div class="operation-table">
          <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            tableTitle="有效联系人"
            formWidth="300px"
            :items="items"
            :urlParams="urlParams"
            :url="url"
            :btn-operation="btnOperation"
            :showHeader="true"
            allowSelection
            :scroll="{ y: gridHeight }"
            @searchVal='getSearch'
          >
            <template #avatar="{ record }">
              <div class="nameClass">
                <div class="surname" v-if="record.name?.length > 0">
                  {{ record.name?.charAt(0) }}
                </div>
                <!--                <div class="nameText" :title="record.name">{{ record.name }}</div>-->
              </div>
            </template>
            <template #infoList="{ record }">
              <span v-for="(i, index) in record.infoList" :key="i.id">
                {{ i.phone }}
                <span v-if="index !== record.infoList.length - 1">,</span>
              </span>
            </template>
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
          </grid>
        </div>
      </div>
    </div>
  </div>
  <edit-page-one
    v-model:visible="visible"
    :init="initValue"
    @fnOk="handleAddPage"
  />
</template>

<script>
import tipsBar from './components/tipsBar';
import { useRouter, useRoute } from 'vue-router';
import Grid from '@/components/connGrid';
import { reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import dashboardApi from '@/api/dashboard';
import { message } from 'ant-design-vue';
import editPageOne from '@/views/dashboard/components/editPageOne';
import operation from '@/components/operation';

export default {
  name: 'valid',
  components: {
    tipsBar,
    Grid,
    editPageOne,
    operation,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const gridRef = ref();

    const state = reactive({
      visible: false,
      initValue: {},
      name: '',
    });
    const clickHandle = () => {
      router.back();
    };
    return {
      clickHandle,
      state,
      gridRef,
      ...toRefs(state),
      gridHeight: document.body.clientHeight - 280,
      url: '/connections_treasure_box/contact/import-contact/page',
      urlParams: {
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        effective: 1,
      },
      items: [{ key: 'name', label: '搜索姓名、公司、职位' }],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: '',
          btnType: 'primary',
          permission: 'clue:delete',
          fnClick: ids => {
            console.log('ids删除', ids)
            // if (ids.length) {

              dashboardApi
                .removeByContactId({
                  userId: store.state.account.user.id,
                  ids: gridRef.value.handleDelete().contactId,
                  importSituation: route.query.importStatus,
                  effective: 1,
                  selectAll: gridRef.value.selectAll().selectAll,
                  repeat: false,
                  name: state.name,
                })
                .then(res => {
                  if (res) {
                    message.success('删除成功');
                    gridRef.value.refreshGrid(gridRef.value.handleDelete().contactId.length);
                  }
                });
            // }
          },
        },
      ],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'name',
          title: '姓名',
          width: 180,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          title: '公司',
          dataIndex: 'company',
          width: 240,
          ellipsis: true,
        },
        {
          title: '职位',
          dataIndex: 'position',
          width: 150,
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'infoList',
          slots: { customRender: 'infoList' },
          ellipsis: true,
          width: 150,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],

      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'invalid:update',
          fnClick: record => {
            state.visible = true;
            if (
              !record.infoList ||
              record.infoList.length === 0 ||
              !record.infoList[0].phone
            ) {
              record['infoList'] = [];
              record['infoList'][0] = {
                userId: record.userId,
                phone: '',
              };
            }
            Object.assign(state.initValue, JSON.parse(JSON.stringify(record)));
          },
        },
      ],
      // 有效联系人编辑弹窗
      handleAddPage(value) {
        state.visible = value;
        gridRef.value.refreshGrid();
      },
      getSearch: parmas => {
        console.log('parmas筛选', parmas )
        state.name = parmas.name

      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.valid-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.operation {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
}
.surname {
  width: 36px;
  height: 36px;
  display: inline-block;
  background-color: @primary-color;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  //line-height: 36px;
  color: #fff;
  //margin-right: 8px;
  min-width: 36px;
  padding-top: 4px;
}
.iss-main /deep/ .ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
}
.nameClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .surname {
  }
  .nameText {
    width: 100px;
  }
}
</style>
